.deliveries-content{

  background: $white;
  @include mb(3);

  .deliveries-title{
    @extend .bg-primary;
    @include pb(1);
    @include pt(1);
    @extend .justify-content-between;
    @extend .align-items-center;
  }

  .job-count{
    @extend .p-2;
    @extend .d-flex;
    @extend .flex-row;
    svg{@extend .text-white; @include ml(2); font-size: 2.2em;}
    span{padding:0.3em 0.5em; margin-top:-2px; font-size: 1.2em}
    @extend .align-items-center;
    font-weight: 700;

    span{@extend .bg-yellow-dark; @extend .border-rad-5; width:35px; ; @extend .d-flex; @extend .align-items-center; @extend .justify-content-center;}
  }


  .list-group-item{
    &:hover{cursor:default}
    &.webcam{border-top:1px solid $white !important;}
  }

  .delivery-list-detail{

   @extend .bg-body;
    //  ul li{ @extend .p-3;
    //    .btn-sort{@extend .btn-blue-base; @extend .p-2; &:hover{color: $yellow-dark; svg{color: $white}} svg{margin-right:0 !important;}}
    //    .btn-back-deliveries{margin-bottom: 0px;}
    //
    //    @include ctm-breakpoint(480px){
    //      .btn-back-deliveries{margin-top:0px !important;}
    //    }
    //  }
    //
    .job-details{
      @extend .bg-primary;
      @include  p(3);
      @include  mt(3);
      @include mb(3);
      @extend .align-items-start;
      @extend .text-left;
      div{@include mb(3);
      &:first-child{margin-top:0px !important;}
      &:last-child{margin-bottom:0px !important;}}
      .bg-white{@extend .p-3; svg{@include mr(2); font-size: 1.2em}}

      svg.fa-info-circle,svg.fa-file,svg.fa-map-marked{font-size: 1.4rem}
    }

    .btn-container{
      @extend .equal-spacing-container;
      .btn-label {
        @extend .d-none;
        @extend .d-sm-inline;
        @include ml(2);
      }
    }

    .pdf-form-content {
      @include mb(3);

      &.mobile {
        display: none;

        &.active {
          display: flex;
          @extend .flex-column;
        }
      }
    }

    .formfields{
      list-style: none;
      margin:0;
      @extend .pb-3;
      @extend .bg-grey-bright;
      .fields-group{
        @extend .justify-content-end;
        @extend .p-3;
        @extend .bg-grey-bright;
        @extend .border-top;
        @extend .border-white;
        @extend .align-items-end;
        &.active{@extend .bg-cyan; h3{@extend .text-white;}
          .job-header h3{@extend .mb-0; @include ml(3)}
          .job-header svg{color: $yellow-dark}
        }
        &:first-child{border:none !important;}
        &:nth-child(odd){ @extend .bg-grey-bright;  label{color: $charcoal} span{color: $charcoal}}
        // &:nth-child(even){ @extend .bg-cyan; label{color: $white} span{color: $white}}
        &:nth-child(even){ @extend .bg-grey-bright;  label{color: $charcoal} span{color: $charcoal}}
        &:last-child{@include mb(3); border-bottom:1px solid $white !important; margin-bottom: 0px !important;}
      }
      .field{  @extend .align-items-end;   @extend .justify-content-end;}
    }

      .btn-holders button[class^="btn-close-fullpdf"]{
        background: $grey-bright !important;
      }

      #pdf-view{margin:0 !important;}

      .doc-details {color:$white; svg{@include mr(2);}}
    }

  .delivery-list{

    .list-group-item {
      @extend .p-3;
      &:nth-child(odd){ @extend .text-grey-pestal;}

      div[class^="add"]{a{@extend .text-grey-pestal; &:hover{@extend .text-cyan;}}}

      &:nth-child(even){
        div[class^="add"]{a{@extend .text-grey-pestal; &:hover{@extend .text-cyan;}}}
      }

      .btn-sign,.btn-phone,.btn-location,.btn-message,.btn-camera-delivery{ @extend .border-blue-sky; @extend .solid-2-line;
        svg{margin-right:0 !important; font-size: 1.4em; color: $blue-sky}
      }
      .btn-message{@include mr(3); background: $white}
    }

    div[class^="add-holder"]{
      @extend .d-flex; @extend .flex-row; @extend .align-items-center;
      @extend .justify-content-between; svg{font-size: 1.8em; @include mr(3)}
      a{@extend .w-100; @extend .d-flex;  @extend .flex-row;  @extend .align-items-center; @extend .justify-content-between;}
      .icon{@include ml(3); svg{@include mr(0);}}
    }

    div[class^="add-detail"]{
      @extend .text-grey-pestal;
      @extend .bg-white;
      @extend .mt-2;
      @extend .mb-2;
      @extend .p-2;
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-start;
      @extend .justify-content-start;
      @extend .flex-wrap;
      a[class^="btn"]{
        @include mr(3);
        &:last-child{@include mr(0);}
      }
      @include ctm-breakpoint(420px){
        .btn-phone{margin-right: 0.5em !important;}
        a[class^="btn"]{margin-right: 0.5em !important;  &:last-child{margin-bottom: 0 !important;}}
      }
      @include ctm-breakpoint(320px){
        a[class^="btn"]{margin-right: 0.2em !important; padding: 1.2em 1.2em;}
        .btn-phone{margin-right: 0.2em !important;}
      }
    }
  }

}
//.darkTheme .deliveries-content{
//  background: $charcoal;
//}
//.job-count{
//  @extend .p-2;
//  @extend .d-flex;
//  @extend .flex-row;
//  svg{@extend .text-white; @include ml(2); font-size: 2.2em;}
//  span{padding:0.3em 0.5em; margin-top:-2px; font-size: 1.2em}
//  @extend .align-items-center;
//  font-weight: 700;
//
//  span{@extend .bg-yellow-dark; @extend .border-rad-5; width:35px; ; @extend .d-flex; @extend .align-items-center; @extend .justify-content-center;}
//}
//
//.deliveries-title{
//  @extend .bg-blue-base;
//  @extend .p-1;
//  @extend .justify-content-between;
//  @extend .align-items-center;
//}
//
//.delivery-list{
//
//  .list-group-item {
//    @extend .p-3;
//    &:nth-child(odd){ @extend .text-grey-pestal;}
//
//    div[class^="add"]{a{@extend .text-grey-pestal; &:hover{@extend .text-cyan;}}}
//
//    &:nth-child(even){
//      div[class^="add"]{a{@extend .text-grey-pestal; &:hover{@extend .text-cyan;}}}
//    }
//
//    .btn-sign,.btn-phone,.btn-location,.btn-message,.btn-camera-delivery{ @extend .border-blue-sky; @extend .solid-2-line;
//      svg{margin-right:0 !important; font-size: 1.4em; color: $blue-sky}
//
//      &:hover{@extend .bg-blue-base; svg{color: $white}}
//    }
//    .btn-message{@include mr(3); background: $white}
//  }
//
//  div[class^="add-holder"]{
//    @extend .d-flex; @extend .flex-row; @extend .align-items-center;
//    @extend .justify-content-between; svg{font-size: 1.8em; @include mr(3)}
//    a{@extend .w-100; @extend .d-flex;  @extend .flex-row;  @extend .align-items-center; @extend .justify-content-between;}
//    .icon{@include ml(3); svg{@include mr(0);}}
//  }
//
//  div[class^="add-detail"]{
//    @extend .text-grey-pestal;
//    @extend .bg-white;
//    @extend .mt-2;
//    @extend .mb-2;
//    @extend .p-2;
//    @extend .d-flex;
//    @extend .flex-row;
//    @extend .align-items-start;
//    @extend .justify-content-start;
//    @extend .flex-wrap;
//    a[class^="btn"]{
//      @include mr(3);
//      &:last-child{@include mr(0);}
//    }
//    @include ctm-breakpoint(420px){
//      .btn-phone{margin-right: 0.5em !important;}
//      a[class^="btn"]{margin-right: 0.5em !important;  &:last-child{margin-bottom: 0 !important;}}
//    }
//    @include ctm-breakpoint(320px){
//      a[class^="btn"]{margin-right: 0.2em !important; padding: 1.2em 1.2em;}
//      .btn-phone{margin-right: 0.2em !important;}
//    }
//  }
//}
//
//.darkTheme .delivery-list{
//  ul li div[class^="add"]{a{@extend .text-white; &:hover{@extend .text-cyan;}}}
//}
//
//.delivery-list-top{
//  @extend .d-flex;
//  @extend .flex-row;
//  @extend .align-items-center;
//  @extend .justify-content-between;
//  @extend .bg-grey-bright;
//  // @extend .p-3;
//  @extend .w-100;
//  display: none;
//}
//
//.darkTheme .delivery-list-top{
//  @extend .bg-grey-smoke;
//}
//

//.darkTheme .delivery-list-detail{
//  .job-guid{@extend .text-charcoal;}
//  .user-details {color:$charcoal;}
//}
//.delivery-map,.pdf-form-holder{
//  @include mb(3);
//  @extend .align-items-center;
//  &.active{  @extend .d-flex;
//    @extend .flex-column;
//    overflow: auto;
//  }
//}
//.delivery-map{
//  display: none;
//}
//
//.delivery-map{
//
//  h3{@extend .bg-blue-base; @extend .w-100; @extend .p-2; @extend .text-white; @include mb(3);}
//}
//
//.pdf-form {
//  width: 100%;
//}
//
//
//.pdf-form-overlay{
//
//  @extend .position-relative;
//  top:0;
//  left:0;
//  @extend .d-flex;
//  @extend .flex-column;
//  @extend .flex-grow-1;
//  @extend .w-100;
//  height:100%;
//  @extend .mb-0;
//
//  form{@extend .w-100;}
//
//  h3{  @extend .text-white; @include mb(3); @include mr(3);}
//}
//
//.pdf-form-holder{
//  @extend .w-100;
//  height: auto;
//  @extend .m-auto;
//}
//
//.pdf-form-holder .form-top-holder{
//  @extend .bg-blue-base;
//  @extend .w-100;
//  max-width: 680px;;
//  @extend .p-2;
//  @extend .d-flex;
//  @extend .flex-row;
//  @extend .align-items-center;
//  @extend .justify-content-between;
//}
//.form-top{
//  @extend .d-flex;
//  @extend .flex-row;
//  @extend .align-items-center;
//  @extend .justify-content-between;
//  @extend .m-auto;
//  @extend .w-100;
//  @include pl(3);
//  padding-right: 0.5em;
//  h3{margin:0 1em 0 0 !important;}
//}
//.pdf-form-holder .form-content{
//  @extend .bg-white;
//  @extend .bg-grey-bright;
//}
//
//.pdf-form-holder .form-content .select-field{
//  @extend .d-flex;
//
//  @extend .align-items-center;
//  @extend .justify-content-center;
//  &.col-row{
//    @extend .flex-row;
//  }
//}
//
//#pdf-view{
//  @extend .d-flex;
//  @extend .m-auto;
//  overflow: hidden;
//  canvas{margin:0 auto}
//  .react-pdf__Page{@extend .d-flex; @extend .flex-column;}
//  a:hover{ width: auto; cursor: pointer}
//
//}
//.full-staticmap,.full-pdf{display: none;
//  position: fixed;
//  background: rgba($black, 0.6);
//  width: 100%;
//  height: 100%;
//
//  @media screen and(max-width: 768px){
//    padding:2em;
//  }
//  &.active{
//    display: flex;
//    top:0px;
//    left:0px;
//    z-index: 4;
//  }
//
//
//  canvas{margin: 0 auto}
//  .staticmap-container,.pdf-container{position:relative; display: flex; margin:2em auto 0;}
//  .pdf-scroller{display:flex; overflow: hidden;
//    @include ctm-breakpoint(420px){
//      padding:1em;
//    }
//  }
//  .pdf-viewport{display:flex; overflow-x: auto; overflow-y:auto;
//    canvas{width:100% !important; height: auto !important;}
//  }
//
//}
//.pdf-viewer-content{
//  @extend .position-relative;
//  @extend .d-flex;
//  @extend .flex-column;
//  width: 100%;
//  margin: 0 auto 1em;
//
//  .sig-thumbnail{
//    @extend .position-absolute;
//    @include ctm-breakpoint(767px){
//      left: 72px;
//      bottom: 226px;
//    }
//    @include ctm-breakpoint(529px){
//      bottom: 216px;
//    }
//  }
//  h3{@extend .bg-blue-base; @extend .p-3; margin-right:0px !important;}
//}
//
//.pdf-form-holder .form-content{
//  @extend .position-relative;
//  @extend .d-flex;
//  @extend .flex-column;
//  @extend .align-items-center;
//  @extend .justify-content-between;
//
//  .formfields{
//    list-style: none;
//    margin:0;
//    @extend .pb-3;
//    @extend .bg-grey-bright;
//    .fields-group{
//      @extend .justify-content-end;
//      @extend .p-3;
//      @extend .bg-grey-bright;
//      @extend .border-top;
//      @extend .border-white;
//      @extend .align-items-end;
//      &.active{@extend .bg-cyan; h3{@extend .text-white;}
//        .job-header h3{@extend .mb-0; @include ml(3)}
//        .job-header svg{color: $yellow-dark}
//      }
//      &:first-child{border:none !important;}
//      &:nth-child(odd){ @extend .bg-grey-bright;  label{color: $charcoal} span{color: $charcoal}}
//      // &:nth-child(even){ @extend .bg-cyan; label{color: $white} span{color: $white}}
//      &:nth-child(even){ @extend .bg-grey-bright;  label{color: $charcoal} span{color: $charcoal}}
//      &:last-child{@include mb(3); border-bottom:1px solid $white !important; margin-bottom: 0px !important;}
//    }
//    .field{  @extend .align-items-end;   @extend .justify-content-end;}
//  }
//}
//.darkTheme .pdf-form-holder .form-content .formfields{
//  @extend .bg-grey-smoke;
//}
//.pdf-form-holder .form-content .formfields .fields-group{
//
//  h3{margin:0 !important;}
//
//  &:nth-child(odd){
//    .orientation-message{@extend .bg-grey-bright; color: $charcoal !important; @extend .p-3;}
//  }
//
//  &:nth-child(even){
//    .orientation-message{@extend .bg-grey-bright; color: $charcoal !important; @extend .p-3;}
//  }
//  .label{text-align: left; @extend .d-flex; @extend .align-items-start;}
//  .field{text-align: left; @extend .d-flex; @extend .align-items-end;}
//  &.input-text,&.dropdown{input,select{width: 100%}}
//  &.dropdown{select{@extend .d-flex; max-width: 100%;  @extend .align-items-end;
//    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
//    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.425);
//    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.35);
//  }}
//  &.checkbox {
//    .field{ @extend .d-flex; @extend .align-items-start;
//      padding:0 !important;
//      .yesnoswitch{min-width: 76px}
//    }
//
//    input[type="checkbox"]{width: 0px;}
//  }
//}
//.darkTheme .pdf-form-holder .form-content .formfields {
//
//  .fields-group{
//    &:nth-child(odd){ @extend .bg-grey-smoke;  label{@extend .text-white} span{@extend .text-white}}
//    &:nth-child(even){ @extend .bg-grey-smoke;  label{@extend .text-white} }
//    &.checkbox {span{@extend .text-white}}
//  }
//
//}

.signatureCanvas{
  margin:0;
  @include mb(3);
  @extend .mt-3;
  border: 1px solid $charcoal;
  @extend .bg-white;

  //@media screen and (orientation: landscape)  {
    display: flex !important;
    margin: 0 auto;
  //}

  @media screen and (max-width:743px) and (orientation: portrait)  {
    display: none !important;
  }

  @include ctm-breakpoint(480px){
    max-width: 420px;
    width:100%;
  }
  @include ctm-breakpoint(360px){
    max-width: 380px;
    width:100%;
  }

  &.is-invalid {
    border-color: $red;
    border-width: 0.15rem;
  }

  &.disabled {
    background-color: lightgrey !important;
  }
}
//
//.submit-component{
//  &.hide{display: none}
//}
//
//.darkTheme .sign-component{
//  @extend .bg-grey-smoke;
//  h3{ @extend .text-white;}
//}
//
//.darkTheme .data-loading {
//  @extend .bg-grey-smoke;
//  @extend .text-white;
//}
